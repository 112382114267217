<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-17 16:56:49
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-07 15:39:45
-->

<template>
  <el-scrollbar v-if="!showEditView">
    <el-card class="box">
      <!-- <el-steps :active="active" finish-status="success">
      <el-step title="类目选择"></el-step>
      <el-step title="编辑内容"></el-step>
    </el-steps> -->
      <div>选择分类</div>
    </el-card>

    <el-card class="box" v-loading="loading">
      <div class="grid">
        <div class="column">
          <el-scrollbar>
            <div
              :class="
                selectionList[item.level] != null &&
                selectionList[item.level].id == item.id
                  ? ' item active'
                  : 'item'
              "
              v-for="item in arrayList[1]"
              :key="item.id"
              @click="choose(item)"
            >
              <span>{{ item.name }}</span>
              <el-icon v-if="!item.is_leaf">
                <ArrowRightBold />
              </el-icon>
            </div>
          </el-scrollbar>
        </div>
        <div class="column">
          <el-scrollbar>
            <div
              :class="
                selectionList[item.level] != null &&
                selectionList[item.level].id == item.id
                  ? ' item active'
                  : 'item'
              "
              v-for="item in arrayList[2]"
              :key="item.id"
              @click="choose(item)"
            >
              <span>{{ item.name }}</span>
              <el-icon v-if="!item.is_leaf">
                <ArrowRightBold />
              </el-icon>
            </div>
          </el-scrollbar>
        </div>
        <div class="column">
          <el-scrollbar>
            <div
              :class="
                selectionList[item.level] != null &&
                selectionList[item.level].id == item.id
                  ? ' item active'
                  : 'item'
              "
              v-for="item in arrayList[3]"
              :key="item.id"
              @click="choose(item)"
            >
              <span>{{ item.name }}</span>
              <el-icon v-if="!item.is_leaf">
                <ArrowRightBold />
              </el-icon>
            </div>
          </el-scrollbar>
        </div>
        <div class="column">
          <el-scrollbar>
            <div
              :class="
                selectionList[item.level] != null &&
                selectionList[item.level].id == item.id
                  ? ' item active'
                  : 'item'
              "
              v-for="item in arrayList[4]"
              :key="item.id"
              @click="choose(item)"
            >
              <span>{{ item.name }}</span>
              <el-icon v-if="!item.is_leaf">
                <ArrowRightBold />
              </el-icon>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </el-card>

    <el-card class="box">
      <el-alert type="info" :closable="false">
        <div class="select-text">
          当前选择：{{ current != null ? current.full_name : "" }}
        </div>
      </el-alert>
    </el-card>
    <el-card class="box">
      <el-button
        type="primary"
        size="large"
        @click="onChooseOk"
        :loading="saving"
        >确认发布该类商品</el-button
      >
    </el-card>
  </el-scrollbar>

  <step-edit v-if="showEditView" @change-category="changeCategory"></step-edit>
</template>

<script>
import StepEdit from "./step_edit.vue";
export default {
  components: {
    StepEdit,
  },
  data() {
    return {
      loading: true,
      saving: false,
      //显示产品编辑页面
      showEditView: false,
      current: null,
      //选则的对象列表集合
      selectionList: [],
      //数据集合，二维数组
      arrayList: [],
    };
  },
  created() {
    var query = this.$route.query;
    if (query && query.id && !query.cat_id) {
      this.showEditView = true;
    } else {
      this.showEditView = false;
      this.loadCategories();
    }
  },
  methods: {
    /**
     *
     */
    loadCategories(father) {
      this.$http
        .get(
          "seller/v1/goods/categories?fatherId=" + (father ? father.id : "0")
        )
        .then((res) => {
          if (res.code == 0) {
            if (!father) {
              this.arrayList[1] = res.data;
            } else {
              this.arrayList[father.level + 1] = res.data;
            }
          }
          this.loading = false;
        });
    },
    /**
     * 点击选择
     * @param {*} item
     */
    choose(item) {
      this.current = item;
      if (item.level == 1) {
        this.arrayList[2] = [];
        this.arrayList[3] = [];
        this.arrayList[4] = [];
        console.log("aaa");
      } else if (item.level == 2) {
        this.arrayList[3] = [];
        this.arrayList[4] = [];
      } else if (item.level == 3) {
        this.arrayList[4] = [];
      }
      this.loadCategories(item);
    },

    /**
     * 确认选择发布该类商品
     */
    onChooseOk() {
      this.$router.push({
        path: "/goods/edit",
        replace: true,
        query: { id: 1, action: "edit" },
      });

      if (this.current == null) {
        return;
      }
      if (!this.current.is_leaf) {
        this.$message({
          message: "请选择完整分类",
        });
        return;
      }

      this.saving = true;
      this.$http
        .post("seller/v1/goods/create", {})
        .then((res) => {
          if (res.code == 0) {
            var data = res.data;
            this.$router.push({
              path: "/goods/edit",
              replace: true,
              query: { id: data.id, action: "edit" },
            });
            // this.$route.push({ path: '/goods/add', replace: true, params: { id: res.data.id } });
            // this.showEditView = true;
          }
        })
        .catch(() => {
          this.saving = false;
        });

      //存储当前选择的分类
      // localStorage.setItem(
      //   this.$cfg.CURRENT_CATEGORY_KEY,
      //   JSON.stringify(this.current)
      // );
    },

    /**
     * 更改选择类目
     */
    changeCategory() {
      localStorage.removeItem(this.$cfg.CURRENT_CATEGORY_KEY);
      this.current = null;
      this.showEditView = false;
      if (this.level0List.length == 0) {
        this.loadCategories();
      }
    },
  },
};
</script>

<style  scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 400px;
  width: 90%;
}

.grid .column {
  border: 1px solid var(--el-border-color-light);
  border-right: none;
  height: 400px;
  display: flex;
  flex-direction: column;
}

.grid .column:last-child {
  border-right: 1px solid var(--el-border-color-light);
}

.column .item {
  font-size: 14px;
  padding: 6px 10px;
  cursor: pointer;
  color: #909399;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.column .item.active {
  background: var(--el-color-primary-light-8);
}

.column .item:hover {
  background: var(--el-color-info-light-7);
}

.column .item.active:hover {
  background: var(--el-color-primary-light-8);
}

.select-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>